import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger</h1>

                <h2>Suspect charged in scandal to dishonor Hero</h2>

                <p>Borachio, who arrived in Messina as part of an entourage accompanying Don John, confessed early this morning to deceptively staging explicit pictures without the knowledge or consent of Margaret, longtime friend of Hero and her cousin, Beatrice. This new evidence officially clears any doubts as to the honorable reputation of the late Hero.
The suspect is charged with unlawful surveillance and claims that he was paid by the General’s stepbrother to stage the picture. Borachio further claims that Don John was the one who captured the image. When asked about the potential motive, Borachio alleged that Don John was obsessed with Hero and jealous of Claudio. 
Borachio’s confession was recorded by Dogberry, who detained the suspect until the actual authorities arrived. Dogberry, who appeared distressed, called for additional charges to be filed against the suspect in connection to numerous and near ceaseless complaints that Borachio had called him “an ass.” However, a person cannot, in fact, be criminally charged for calling another person an ass. 
Don John remains at large. There is no word yet on whether Margaret plans to press further charges. 
</p>
            </div>
        </Layout>
    )
}